import React from "react";
import Snowfall from "react-snowfall";
import "./App.css";
import Center from "./components/Center";
import { Brief } from "./components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const isMobile = window.screen.width < 768;

  return (
    <>
      <Snowfall
        snowflakeCount={100}
        style={{ opacity: 0.5, zIndex: 999 }}
        radius={[0, 2]}
      />
      <div className="main-container">
        <Center />
        <img
          className="brief-image"
          src={
            isMobile
              ? "/assets/images/bottom-side-mobile.webp"
              : "/assets/images/bottom-side-desktop.webp"
          }
          alt=""
        />
        {/* <Brief /> */}
      </div>
      <ToastContainer limit={3} />
    </>
  );
}

export default App;
