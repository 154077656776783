import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const Center = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    axios
      .post("https://api.quickbrief.co/api/WaitList/addWaitList", {
        email,
      })
      .then(function (response) {
        toast(
          response.data.isSuccess
            ? "You are on the waitlist 🚀"
            : response.data.message,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  return (
    <div className="center">
      <div className="logo">
        <img
          src="/assets/images/app-logo.webp"
          alt="QuickBrief Logo"
          className="logo-image"
        />
      </div>
      <div>
        <h1 className="title">Launching Very Soon</h1>
      </div>
      <div>
        <p className="description">
          We will be at your service in a very short time. Quick Brief will be
          with you whenever you want to improve yourself in your applications,
          experiments, UI/UX.
        </p>
      </div>
      <div>
        <form className="join-waitlist-bar" onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Enter Your E-Mail"
            className="join-waitlist-input"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="join-waitlist-button">
            Join Waitlist
          </button>
        </form>
      </div>
    </div>
  );
};

export default Center;
